/* eslint-disable no-unused-vars */
import React, { Fragment, lazy, useState, useEffect, useMemo, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';
import Signin1 from './views/auth/signin/SignIn1';
import { mainDomain } from './helpers/Urls';
import NotFoundPage from './views/NotFound/NotFound';

export const RenderRoutes = ({ routes = [] }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const auth = async (signal) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsAuth(false);
        return;
      }

      const response = await axios.get(`${mainDomain}api/user/current-user`, {
        headers: { Authorization: `Bearer ${token}` },
        signal
      });

      if (response?.data?.user?._id) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
    } catch (error) {
      if (!signal.aborted) {
        console.error('Auth Error:', error.message);
        setIsAuth(false);
      }
    } finally {
      if (!signal.aborted) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    auth(controller.signal);

    return () => controller.abort(); // Cleanup
  }, []);

  const renderRoutes = useMemo(
    () =>
      routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Element = route.element;

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>{route.routes ? <RenderRoutes routes={route.routes} /> : <Element />}</Layout>
              </Guard>
            }
          />
        );
      }),
    [routes]
  );

  if (isLoading) return <Loader />;

  return (
    <Suspense>
      {!isAuth ? (
        <>
          <Navigate to="/signin" />
          <Routes>
            <Route path="/signin" element={<Signin1 />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </>
      ) : (
        <Routes>{renderRoutes}</Routes>
      )}
    </Suspense>
  );
};

const routes = [
  {
    path: '*',
    layout: AdminLayout,
    routes: [
      {
        path: '/',
        // path: '/app/dashboard/default',
        element: lazy(() => import('./views/dashboard'))
      },
      {
        path: '/signin',
        element: lazy(() => import('./views/auth/signin/SignIn1'))
      },
      {
        path: '/basic/button',
        element: lazy(() => import('./views/ui-elements/basic/BasicButton'))
      },
      {
        path: '/basic/badges',
        element: lazy(() => import('./views/ui-elements/basic/BasicBadges'))
      },
      {
        path: '/basic/breadcrumb',
        element: lazy(() => import('./views/ui-elements/basic/BasicBreadcrumb'))
      },
      {
        path: '/basic/pagination',
        element: lazy(() => import('./views/ui-elements/basic/BasicPagination'))
      },
      {
        path: '/basic/collapse',
        element: lazy(() => import('./views/ui-elements/basic/BasicCollapse'))
      },
      {
        path: '/basic/tabs-pills',
        element: lazy(() => import('./views/ui-elements/basic/BasicTabsPills'))
      },
      {
        path: '/basic/typography',
        element: lazy(() => import('./views/ui-elements/basic/BasicTypography'))
      },
      {
        path: '/forms/form-basic',
        element: lazy(() => import('./views/forms/FormsElements'))
      },
      {
        path: '/forms/AddUserForm',
        element: lazy(() => import('./views/tables/Functions/AddUserForm'))
      },
      {
        path: '/forms/UpdateForm',
        element: lazy(() => import('./views/tables/Functions/UpdateUserForm'))
      },
      {
        path: '/tables/bootstrap',
        element: lazy(() => import('./views/tables/BootstrapTable'))
      },
      {
        path: '/hoverTables/bootstrap',
        element: lazy(() => import('./views/tables/HoverTable'))
      },
      {
        path: '/charts/nvd3',
        element: lazy(() => import('./views/charts/nvd3-chart'))
      },
      // ***** For Sub Admin *****
      {
        path: '/masters/subAdmin',
        element: lazy(() => import('./components/subAdmin/SubAdmin'))
      },
      {
        path: '/masters/addSubAdmin',
        element: lazy(() => import('./components/subAdmin/AddSubAdmin'))
      },
      {
        path: '/masters/editSubAdmin/:userId',
        element: lazy(() => import('./components/subAdmin/EditSubAdmin'))
      },

      {
        path: '/masters/users',
        element: lazy(() => import('./components/users/User'))
      },
      {
        path: '/user/info/:id',
        element: lazy(() => import('./components/users/Information'))
      },
      {
        path: '/masters/city',
        element: lazy(() => import('./components/city/City'))
      },
      {
        path: '/masters/AddCity',
        element: lazy(() => import('./components/city/AddCity'))
      },
      {
        path: '/masters/EditCity/:id',
        element: lazy(() => import('./components/city/EditCity'))
      },
      {
        path: '/masters/state',
        element: lazy(() => import('./components/state/State'))
      },
      {
        path: '/masters/AddState',
        element: lazy(() => import('./components/state/AddState'))
      },
      {
        path: '/masters/EditState/:id',
        element: lazy(() => import('./components/state/EditState'))
      },
      {
        path: '/masters/country',
        element: lazy(() => import('./components/country/Country'))
      },
      {
        path: '/masters/AddCountry',
        element: lazy(() => import('./components/country/AddCountry'))
      },
      {
        path: '/masters/EditCountry/:id',
        element: lazy(() => import('./components/country/EditCountry'))
      },
      {
        path: '/masters/education',
        element: lazy(() => import('./components/education/Education'))
      },
      {
        path: '/masters/AddEducation',
        element: lazy(() => import('./components/education/AddEducation'))
      },
      {
        path: '/masters/EditEducation/:id',
        element: lazy(() => import('./components/education/EditEducation'))
      },
      {
        path: '/masters/interest',
        element: lazy(() => import('./components/interest/Interest'))
      },
      {
        path: '/masters/AddInterest',
        element: lazy(() => import('./components/interest/AddInterest'))
      },
      {
        path: '/masters/EditInterest/:id',
        element: lazy(() => import('./components/interest/EditInterest'))
      },
      {
        path: '/masters/language',
        element: lazy(() => import('./components/language/Language'))
      },
      {
        path: '/masters/AddLanguage',
        element: lazy(() => import('./components/language/AddLanguage'))
      },
      {
        path: '/masters/EditLanguage/:id',
        element: lazy(() => import('./components/language/EditLanguage'))
      },
      {
        path: '/masters/skillsCategory',
        element: lazy(() => import('./components/skillCategory/SkillCategory'))
      },
      {
        path: '/masters/AddSkillsCategory',
        element: lazy(() => import('./components/skillCategory/AddSkillCategory'))
      },
      {
        path: '/masters/EditSkillsCategory/:id',
        element: lazy(() => import('./components/skillCategory/EditSkillCategory'))
      },
      {
        path: '/masters/skills',
        element: lazy(() => import('./components/skills/Skills'))
      },
      {
        path: '/masters/AddSkills',
        element: lazy(() => import('./components/skills/AddSkills'))
      },
      {
        path: '/masters/EditSkills/:id',
        element: lazy(() => import('./components/skills/EditSkills'))
      },
      {
        path: '/masters/university',
        element: lazy(() => import('./components/university/University'))
      },
      {
        path: '/masters/AddUniversity',
        element: lazy(() => import('./components/university/AddUniversity'))
      },
      {
        path: '/masters/EditUniversity/:id',
        element: lazy(() => import('./components/university/EditUniversity'))
      },
      {
        path: '/masters/TradeType',
        element: lazy(() => import('./components/tradeType/TypeTrade'))
      },
      {
        path: '/masters/AddTradeType',
        element: lazy(() => import('./components/tradeType/AddTradeType'))
      },
      {
        path: '/masters/EditTradeType/:id',
        element: lazy(() => import('./components/tradeType/EditTradeType'))
      },
      {
        path: '/masters/CatTradeType',
        element: lazy(() => import('./components/catTradeType/CatTradeType'))
      },
      {
        path: '/masters/EditCatTradeType/:id',
        element: lazy(() => import('./components/catTradeType/EditCatTradeType'))
      },
      {
        path: '/masters/AddCatTradeType',
        element: lazy(() => import('./components/catTradeType/AddCatTradeType'))
      },
      {
        path: '/masters/SubCatTradeType',
        element: lazy(() => import('./components/subCatTradeType/SubCatTradeType'))
      },
      {
        path: '/masters/EditSubCatTradeType/:id',
        element: lazy(() => import('./components/subCatTradeType/EditSubCatTradeType'))
      },
      {
        path: '/masters/AddSubCatTradeType',
        element: lazy(() => import('./components/subCatTradeType/AddSubCatTradeType'))
      },
      {
        path: '/sample-page',
        element: lazy(() => import('./views/extra/SamplePage'))
      },
      {
        path: '/report',
        element: lazy(() => import('./components/report/Report'))
      },
      {
        path: '/report/addReport',
        element: lazy(() => import('./components/report/AddReport'))
      },
      {
        path: '/report/editReport/:id',
        element: lazy(() => import('./components/report/EditReason'))
      },
      //------ Badge ------
      {
        path: '/badge',
        element: lazy(() => import('./components/Badge/Badge'))
      },
      {
        path: '/addbadge',
        element: lazy(() => import('./components/Badge/AddBadge'))
      },
      {
        path: '/editbadge/:id',
        element: lazy(() => import('./components/Badge/EditBadge'))
      },
      {
        path: '/issuereport',
        element: lazy(() => import('./components/IssueReport/IssueReport'))
      },
      {
        path: '/issueDetails/:id',
        element: lazy(() => import('./components/IssueReport/IssueDetails'))
      },
      {
        path: '/project',
        element: lazy(() => import('./components/project/ProjectList'))
      },
      {
        path: '/projectDetails/:id',
        element: lazy(() => import('./components/project/ProjectDetails'))
      },

      {
        path: '/badgerequest',
        element: lazy(() => import('./components/userBadgeRequest/BadgeRequest'))
      },

      {
        path: '/test',
        element: lazy(() => import('./components/test/Test'))
      },

      {
        path: '/setting',
        element: lazy(() => import('./components/settings/Settings'))
      },

      // {
      //
      //   path: '/sub-testing/AddSubTesting',
      //   element: lazy(() => import('./components/subTesting/AddSubTesting'))
      // },
      // {
      //
      //   path: '/sub-testing/EditSubTesting/:id',
      //   element: lazy(() => import('./components/subTesting/EditSubTesting'))
      // },
      {
        path: '/AI/all-entry',
        element: lazy(() => import('./components/AI/AllEntry'))
      },
      {
        path: '/AI/AddEntry',
        element: lazy(() => import('./components/AI/AddEntry'))
      },
      {
        path: '/AI/EditEntry/:id',
        element: lazy(() => import('./components/AI/EditEntry'))
      },
      {
        path: '/tansaction',
        element: lazy(() => import('./components/transaction/Transaction'))
      },
      {
        path: '/bankdetails',
        element: lazy(() => import('./components/transaction/BankDetails'))
      },
      {
        path: '/bankdetailsview/:id',
        element: lazy(() => import('./components/transaction/BankDetailsView'))
      },
      {
        path: '/projectwisepayment',
        element: lazy(() => import('./components/projectTransaction/ProjectWisePayment'))
      },
      {
        path: '/projectwisepaymentDetails/:id',
        element: lazy(() => import('./components/projectTransaction/PaymentDetails'))
      },
      {
        path: '*',
        element: lazy(() => import('./views/NotFound/NotFound'))
      }
    ]
  }
];

RenderRoutes.propTypes = {
  routes: PropTypes.array
};

export default routes;
